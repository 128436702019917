const handleClick = (script, argument) => {
  window.jsx.evalFile("./Harchenko/AE2 Extension/ppData.jsx");
  window.jsx.evalScript(
    `run(${script}(${JSON.stringify(argument)}));`,
    function (result) {
      return result;
    }
  );
  return false;
};

export default handleClick;
