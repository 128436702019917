import React, { useState, useEffect } from "react";
import "./CollectFiles.css";
import useVisible from "../../hooks/useVisible.jsx";
//import getGoogleFonts from "../../utils/buttons/getGoogleFonts";
import getFootages from "../../utils/buttons/getFootages";
import handleClick from "../../utils/handleClick.js";
//import zipTemplate from "../../utils/zipTemplate.js";

function CollectFiles({
  onPopupStateChange,
  selectedTag,
  buttonTag,
  generateButtonTags,
}) {
  const FILES_FOLDER = "TemplateAssetFolder";
  const [isVisible, setIsVisible] = useVisible("CollectFilesVisibility");
  const [helpPdf, setHelpPdf] = useVisible("CollectFilesPDF");
  const [templateFolder, setTemplateFolder] = useState(
    localStorage.getItem(FILES_FOLDER) === "true" // Преобразуем из строки "true"/"false" в булево значение
  );
  //const [fonts, setFonts] = useVisible("CollectFilesFonts");
  const [footages, setFootages] = useVisible("CollectFilesFootages");
  //const [zip, setZip] = useVisible("CollectFilesZip");
  const [type, setType] = useState("pp");
  const [format, setFormat] = useState("txt");

  useEffect(() => {
    localStorage.setItem(FILES_FOLDER, templateFolder); // Автоматически преобразуется в строку
  }, [templateFolder]);

  useEffect(() => {
    localStorage.setItem("CollectFilesFormat", format);
  }, [format]); // Эффект отслеживает изменения format

  // Функция для переключения между 'rtf' и 'txt'
  const toggleFormat = () => {
    setFormat((currentFormat) => (currentFormat === "rtf" ? "txt" : "rtf"));
  };

  useEffect(() => {
    if (onPopupStateChange) {
      onPopupStateChange(isVisible);
    }
  }, [isVisible]);

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-folder"></i>
        </div>
        <div className="button-card__title">Collect Files</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <input
            type="checkbox"
            id="save-template-folder"
            checked={templateFolder}
            onChange={() => setTemplateFolder(!templateFolder)}
            className="hidden-checkbox"
          />
          <label htmlFor="save-template-folder">
            <span className="custom-checkbox"></span>
            <span className="label-text">Template Folder</span>
          </label>

          <input
            type="checkbox"
            id="save-user-folder"
            checked={!templateFolder}
            onChange={() => setTemplateFolder(!templateFolder)}
            className="hidden-checkbox"
          />
          <label htmlFor="save-user-folder">
            <span className="custom-checkbox"></span>
            <span className="label-text">User Folder</span>
          </label>
          {/* <input
            type="checkbox"
            id="helpPdf-collect-files"
            checked={helpPdf}
            onChange={() => {
              setHelpPdf(!helpPdf);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="helpPdf-collect-files">
            <span className="custom-checkbox"></span>
            <span className="label-text">Help PDF</span>
          </label> */}
          {/* <input
            type="checkbox"
            id="fonts-collect-files"
            checked={fonts}
            onChange={() => {
              setFonts(!fonts);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="fonts-collect-files">
            <span className="custom-checkbox"></span>
            <span className="label-text">Google Fonts</span>
          </label> */}
          {/* <input
            type="checkbox"
            id="footages-collect-files"
            checked={footages}
            onChange={() => {
              setFootages(!footages);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="footages-collect-files">
            <span className="custom-checkbox"></span>
            <span className="label-text">Links Footages</span>
          </label> */}
          {/* <input
            type="checkbox"
            id="zip-collect-files"
            checked={zip}
            onChange={() => {
              setZip(!zip);
            }}
            className="hidden-checkbox"
          />
          <label htmlFor="zip-collect-files">
            <span className="custom-checkbox"></span>
            <span className="label-text">Create Zip</span>
          </label> */}
          {/* <div className="collect-files-format" onClick={toggleFormat}>
            {format}
          </div> */}
        </div>

        <div className="videobolt-tools">
          <div
            onClick={() =>
              handleClick("ppCollectFiles", { templateFolder: templateFolder })
            }
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-folder"></i>
            <span>Collect Files</span>
          </div>
          <div
            onClick={() =>
              handleClick("ppHelpMA", { templateFolder: templateFolder })
            }
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-light fa-file-pdf"></i>
            <span>Help PDF</span>
          </div>
          {/* <div
            onClick={() => getGoogleFonts(false, true, format, type)}
            className="resolution-tools__button"
          >
            <i class="fa-brands fa-google"></i>
            <span>Google Fonts</span>
          </div> */}
          <div
            onClick={() => getFootages(format, type, templateFolder)}
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-image"></i>
            <span>Links Footages</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollectFiles;
