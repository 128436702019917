import React, { useContext } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import handleClick from "../../utils/handleClick.js";
import "./ResolutionTools.css";

function ResolutionTools({ selectedTag, buttonTag, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("ResolutionToolsVisibility");
  const currentUser = useContext(CurrentUserContext);

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-sharp fa-solid fa-high-definition"></i>
          <div />
        </div>
        <div className="button-card__title">Resolution Tools</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
        <div
          onClick={() =>
            handleClick("ppResolution", {
              name: currentUser.login,
              size: [1080, 1080],
            })
          }
          className="resolution-tools__button"
        >
          Square <span>1080x1080</span>
        </div>
        <div
          onClick={() =>
            handleClick("ppResolution", {
              name: currentUser.login,
              size: [1080, 1350],
            })
          }
          className="resolution-tools__button"
        >
          Post <span>1080x1350</span>
        </div>
        <div
          onClick={() =>
            handleClick("ppResolution", {
              name: currentUser.login,
              size: [1080, 1920],
            })
          }
          className="resolution-tools__button"
        >
          Vertical <span>1080x1920</span>
        </div>
        <div
          onClick={() =>
            handleClick("ppResolution", {
              name: currentUser.login,
              size: [1920, 1080],
            })
          }
          className="resolution-tools__button"
        >
          Full HD <span>1920x1080</span>
        </div>
        <div
          onClick={() =>
            handleClick("ppResolution", {
              name: currentUser.login,
              size: [2560, 1080],
            })
          }
          className="resolution-tools__button"
        >
          UltraWide <span>2560x1080</span>
        </div>
        <div
          onClick={() =>
            handleClick("ppResolution", {
              name: currentUser.login,
              size: [2560, 1440],
            })
          }
          className="resolution-tools__button"
        >
          2K <span>2560x1440</span>
        </div>
        <div
          onClick={() =>
            handleClick("ppResolution", {
              name: currentUser.login,
              size: [3840, 2160],
            })
          }
          className="resolution-tools__button"
        >
          4K <span>3840x2160</span>
        </div>
        <div
          onClick={() => handleClick("ppResolution", [])}
          className="resolution-tools__button"
        >
          Other <span>User Size</span>
        </div>

        <div
          onClick={() =>
            handleClick("lockSequence", { comment: "lock", unlock: false })
          }
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-lock"></i>
          <span>Lock Resize</span>
        </div>
        <div
          onClick={() =>
            handleClick("lockSequence", {
              comment: "lock resize",
              unlock: false,
            })
          }
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-hashtag-lock"></i>
          <span>Lock & Resize</span>
        </div>
        <div
          onClick={() =>
            handleClick("lockSequence", { comment: "", unlock: true })
          }
          className="resolution-tools__button"
        >
          <i class="fa-regular fa-lock-open"></i>
          <span>Unlock</span>
        </div>
      </div>
    </>
  );
}

export default ResolutionTools;
