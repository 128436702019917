import React, { useContext, useState } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import UserProfile from "../UserProfile/UserProfile";
import headerLogo from "../../images/header-logo.png";
import handleClick from "../../utils/handleClick.js";
import "./Header.css";

import { capitalizeFirstLetter } from "../../utils/utils.js";

const Header = ({ handleSignOut }) => {
  const currentUser = useContext(CurrentUserContext);
  const [showUserProfile, setShowUserProfile] = useState(false);

  const toggleUserProfile = () => {
    setShowUserProfile(!showUserProfile);
  };

  return (
    <>
      <UserProfile
        isShown={showUserProfile}
        onClose={toggleUserProfile}
        handleSignOut={handleSignOut}
      />
      <div className="header">
        <div>
          <img src={headerLogo} className="header__logo" alt="ae2 logo" />
          {/* <div>Welcome</div>
        <div className="header__user">{capitalizeFirstLetter(currentUser.login)}</div> */}
        </div>
        <div className="header__user_group">
          <div
            className="header__profile"
            onClick={() => handleClick("openTemplateFolder")}
          >
            <i class="fa-regular fa-folder-open"></i>
          </div>
          <div className="header__profile" onClick={toggleUserProfile}>
            <i class="fa-sharp fa-solid fa-bars"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
